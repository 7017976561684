import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
// import ProjectPreviewGrid from '../components/project-preview-grid/project-preview-grid'
import BlogPostPreviewHorizontal from '../components/blog-post-preview-horizontal/blog-post-preview-horizontal'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, videoNullCheck, imageNullCheck } from '../lib/helpers'
import VideoBanner from '../components/video-banner/video-banner'

import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

export const query = graphql`
  query ProjectsPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)projects/" }) {
          id
        title
        _rawBody
				_rawSubtitle
        useVideo
        pageBranding {
          asset {
            _id
          }
        }
        mainVideo {
          embed {
            _type
            asset {
              url
            }
          }
        }
        mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
      }

    projects: allSanityProject(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          _rawCategories(resolveReferences: {maxDepth: 1})
          slug {
            current
          }
        }
      }
    }
  }
`

const ProjectsPage = props => {
  const { data, errors } = props
  const page = (typeof data === "object" && Object.keys(data).length > 0 ? data.page : {}); 
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
	const projectNodes = data && data.projects && mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
  return (
    <Layout
			transparent
		>
      <SEO title='Projects' />
			<VideoBanner { ...page}/>
      <Container>
        {projectNodes && projectNodes.length > 0 && (
					projectNodes.map((node) => (
						<Row className={'mt-5 mb-5'}>
							<Col sm={12} key={node.id}>
								<BlogPostPreviewHorizontal noDate postType={'project'} {...node} />
							</Col>
						</Row>
				))
				)}
      </Container>
    </Layout>
  )
}

export default ProjectsPage
